/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import NavBar from './common/navbar';
import NextLink from 'next/link';
import TopBarDark from './common/topbar-dark';
import {
  Container,
  Row,
  Col,
  NavLink,
  Media,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import search from '../../public/assets/images/icon/search.png';
import cart from '../../public/assets/images/icon/cart.png';
import { useRouter } from 'next/router';
import { getImageDownloadUrl } from '../../utils/images';
import SearchOverlay from './common/search-overlay';
import CartContainer from '../containers/CartContainer';
import Image from 'next/image';
import StoreContext from '../../utils/store/StoreContext';
import { useTranslation } from 'react-i18next';

const HeaderOne = ({ headerClass, topClass, noTopBar }) => {
  const router = useRouter();
  const { store, isOpen, getCurrentDay } = useContext(StoreContext);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const { t } = useTranslation('common');
  const currentDay = getCurrentDay();
  /*=====================
		 Pre loader
		 ==========================*/
  useEffect(() => {
    setTimeout(function () {
      document.querySelectorAll('.loader-wrapper').style = 'display:none';
    }, 2000);

    if (router.asPath !== '/layouts/Christmas')
      window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    let number =
      window.pageXOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    const sticky = document.getElementById('sticky');
    if (sticky) {
      if (number >= 300) {
        if (window.innerWidth < 576) sticky.classList.remove('fixed');
        else sticky.classList.add('fixed');
      } else sticky.classList.remove('fixed');
    }
  };

  const openSearch = () => {
    document.getElementById('search-overlay').style.display = 'block';
  };

  const getDayName = (day) => {
    switch (day) {
      case 'mon':
        return t('Monday');
      case 'tue':
        return t('Tuesday');
      case 'wed':
        return t('Wednesday');
      case 'thu':
        return t('Thursday');
      case 'fri':
        return t('Friday');
      case 'sat':
        return t('Saturday');
      case 'sun':
        return t('Sunday');
      default:
        break;
    }
  };

  return (
    <div>
      <header id="sticky" className={`sticky ${headerClass}`}>
        <div className="mobile-fix-option"></div>
        {/*Top Header Component*/}
        {noTopBar ? '' : <TopBarDark topClass={topClass} store={store} />}

        <Container>
          <Row>
            <Col>
              <div className="main-menu">
                <div className="menu-left">
                  <div className="brand-logo">
                    <NextLink href="/" passHref>
                      <NavLink>
                        <Media
                          src={getImageDownloadUrl(store.image)}
                          alt={store.name}
                          height={80}
                        />
                      </NavLink>
                    </NextLink>
                  </div>
                  {store.controlOpeningHours && (
                    <a href="#" onClick={() => setModal(true)}>
                      <div className="text-center">
                        {isOpen() ? (
                          <h4>
                            <span className="badge badge-success">
                              {t('Open')}
                            </span>
                          </h4>
                        ) : (
                          <h4>
                            <span className="badge badge-warning">
                              {t('Closed')}
                            </span>
                          </h4>
                        )}
                        {currentDay && (
                          <>
                            {currentDay.start} - {currentDay.end}
                          </>
                        )}
                      </div>
                    </a>
                  )}
                </div>
                <div className="menu-right pull-right">
                  <NavBar icon={cart} />

                  <div>
                    <div className="icon-nav cart-menu">
                      <ul>
                        <li className="onhover-div mobile-search">
                          <div>
                            <Image
                              src={search}
                              onClick={openSearch}
                              className="img-fluid"
                              alt=""
                              width={23}
                              height={23}
                            />
                          </div>
                        </li>
                        {!store.onlyCatalog && (
                          <CartContainer layout="ltr" icon={cart} />
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </header>

      <SearchOverlay />
      {store.controlOpeningHours && (
        <Modal isOpen={modal} toggle={toggle} className="" size="xs" centered>
          <ModalHeader toggle={toggle}>{t('Hours of Operation')}</ModalHeader>
          <ModalBody className="info-modal">
            <Row>
              <Col className="text-center mt-2">
                {store.openingHours.map((h, i) => (
                  <p key={i}>
                    <strong>{getDayName(h.day)}:</strong> {h.start} - {h.end}
                  </p>
                ))}
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};

export default HeaderOne;

import React, { useState, useEffect, useContext } from 'react';
import { useRouter } from 'next/router';
import CategoryContext from '../../../utils/categories/CategoryContext';
import { useTranslation } from 'react-i18next';
import FilterContext from '../../../utils/filter/FilterContext';
import Link from 'next/link';

const NavBar = () => {
  const { t } = useTranslation('common');
  const [navClose, setNavClose] = useState({ right: '0px' });
  const router = useRouter();
  const filterContext = useContext(FilterContext);
  const categoryContext = useContext(CategoryContext);
  const categories = categoryContext.categories;
  const setSelectedCategory = filterContext.setSelectedCategory;

  let menuItems = categories.filter((category) => !category.categoryId);
  menuItems = menuItems.map((menuItem) => {
    menuItem.children = categories.filter(
      (category) => category.categoryId == menuItem._id
    );
    return menuItem;
  });

  useEffect(() => {
    if (window.innerWidth < 750) {
      setNavClose({ right: '-410px' });
    }
    if (window.innerWidth < 1199) {
      setNavClose({ right: '-300px' });
    }
  }, []);

  const openNav = () => {
    setNavClose({ right: '0px' });
    if (router.asPath == '/layouts/Gym')
      document.querySelector('#topHeader').classList.add('zindex-class');
  };

  const closeNav = () => {
    setNavClose({ right: '-410px' });
    if (router.asPath == '/layouts/Gym')
      document.querySelector('#topHeader').classList.remove('zindex-class');
  };

  const updateCategory = (category) => {
    setSelectedCategory(category);
  };

  return (
    <div>
      <div className="main-navbar">
        <div id="mainnav">
          <div className="toggle-nav">
            <i
              className="fa fa-bars sidebar-bar"
              onClick={openNav.bind(this)}
            ></i>
          </div>

          <ul className="nav-menu" style={navClose}>
            <li className="back-btn" onClick={closeNav.bind(this)}>
              <div className="mobile-back text-right">
                <i className="fa fa-close pl-2" aria-hidden="true"></i>
              </div>
            </li>
            <li>
              <Link href={`/`} passHref>
                <a className="nav-link">
                  {t('Home')}
                </a>
              </Link>
            </li>
            {menuItems.map((menuItem, i) => {
              return (
                <li key={i}>
                  <Link href={`/search?category=${menuItem.slug}`} passHref>
                    <a
                      className="nav-link"
                      onClick={() => updateCategory(menuItem.slug)}
                    >
                      {' '}
                      {menuItem.name}
                      {menuItem.children.length > 0 && (
                        <span className="sub-arrow"></span>
                      )}
                    </a>
                  </Link>
                  {menuItem.children.length > 0 && (
                    <ul className="nav-submenu opensubmenu">
                      {menuItem.children.map((childrenItem, index) => {
                        return (
                          <li key={index}>
                            <Link href={`/search?category=${childrenItem.slug}`} passHref>
                              <a
                                onClick={() => updateCategory(childrenItem.slug)}
                              >
                                {childrenItem.name}
                              </a>
                             </Link>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NavBar;

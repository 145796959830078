const formatValue = (value, currency) => {
  currency = currency || 'USD';
  let currencyFormat = new Intl.NumberFormat(getLocaleByCurrency(currency), {
    style: 'currency',
    currency: currency,
  });
  if (!value) {
    value = 0;
  }

  return currencyFormat.format(value);
};

const getLocaleByCurrency = (currency) => {
  switch (currency) {
    case 'BRL':
      return 'pt-BR';
    case 'USD':
      return 'en-US';
    case 'EUR':
      return 'de-DE';
    case 'JPY':
      return 'ja-JP';
    case 'MXN':
      return 'es-MX';
    case "ARS":
      return 'es-AR';
    default:
      return 'en-US';
  }
};



export { formatValue };

import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Link from 'next/link';

const Breadcrumbs = ({ title, parent }) => {
  return (
    <div className="breadcrumb-section">
      <Container>
        <Row>
          <Col sm="6">
            <div className="page-title">
              <h2>{title}</h2>
            </div>
          </Col>
          <Col sm="6">
            <nav aria-label="breadcrumb" className="theme-breadcrumb">
              <ol className="breadcrumb">
                {parent ? (
                  parent.map((e, i) => (
                    <li className="breadcrumb-item" key={i}>
                      <Link href={e.href} passHref>
                        <a href="#">{e.name}</a>
                      </Link>
                    </li>
                  ))
                ) : (
                  <li className="breadcrumb-item">
                    <Link href="/" passHref>
                      <a href="#">home</a>
                    </Link>
                  </li>
                )}
              </ol>
            </nav>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Breadcrumbs;

import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
} from 'reactstrap';
import FilterContext from '../../../utils/filter/FilterContext';
import * as ga from '../../../utils/ga';

const closeSearch = () => {
  document.getElementById('search-overlay').style.display = 'none';
};
const SearchOverlay = () => {
  const { t } = useTranslation('common');
  const { setSelectedQuery, pushToSearch } = useContext(FilterContext);

  const [query, setQuery] = useState('');

  const queryChangeHandler = (e) => {
    ga.event({
      action: 'search',
      params: {
        search_term: e.target.value,
      },
    });
    setQuery(e.target.value);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setSelectedQuery(query);
    pushToSearch();
    closeSearch();
  };
  return (
    <div id="search-overlay" className="search-overlay">
      <div>
        <span className="closebtn" onClick={closeSearch} title={t('Close')}>
          ×
        </span>
        <div className="overlay-content">
          <Container>
            <Row>
              <Col xl="12">
                <Form onSubmit={submitHandler}>
                  <FormGroup>
                    <Input
                      type="text"
                      className="form-control"
                      placeholder={t('Search a Product')}
                      onChange={queryChangeHandler}
                    />
                  </FormGroup>
                  <Button type="submit" className="btn btn-primary">
                    <i className="fa fa-search"></i>
                  </Button>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default SearchOverlay;

import React, { Fragment, useContext } from 'react';
import Link from 'next/link';
import { Media } from 'reactstrap';
import CartContext from '../../../utils/cart';
import { getImageDownloadUrl } from '../../../utils/images';
import { formatValue } from '../../../utils/currency';
import StoreContext from '../../../utils/store/StoreContext';

const CartHeader = ({ item }) => {
  const context = useContext(CartContext);
  const { store } = useContext(StoreContext);
  return (
    <Fragment>
      <li>
        <div className="media">
          <Link href={'/product/' + item.slug}>
            <a>
              <Media
                alt=""
                className="mr-3"
                src={getImageDownloadUrl(item.images[0])}
              />
            </a>
          </Link>
          <div className="media-body">
            <Link href={'/product/' + item.id}>
              <a>
                <h4>{item.name}</h4>
              </a>
            </Link>

            <h4>
              <span>
                {item.qty} x{' '}
                {item.promoPrice
                  ? formatValue(item.promoPrice, store.currency)
                  : formatValue(item.salePrice, store.currency)}
              </span>
            </h4>
          </div>
        </div>
        <div className="close-circle">
          <i
            className="fa fa-times"
            aria-hidden="true"
            onClick={() => context.removeFromCart(item)}
          ></i>
        </div>
      </li>
    </Fragment>
  );
};

export default CartHeader;

import React, { useContext, useEffect } from 'react';
import Router from 'next/router';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import HeaderOne from './headers/header-one';
import imgFavicon from '../public/assets/favicon.png';
import StoreContext from '../utils/store/StoreContext';
import Breadcrumbs from './common/widgets/breadcrumbs';
import { Container } from 'reactstrap';

const DynamicMasterFooter = dynamic(() =>
  import('./footers/common/MasterFooter')
);

export default function Layout({
  title,
  showBreadCrumbs,
  parent,
  subTitle,
  children,
}) {
  const { store } = useContext(StoreContext);

  useEffect(() => {
    import("react-facebook-pixel")
      .then((x) => x.default)
      .then((ReactPixel) => {
        if(store.pixelFacebook){
          ReactPixel.init(store.pixelFacebook);
          ReactPixel.pageView();

          Router.events.on("routeChangeComplete", () => {
            ReactPixel.pageView();
          });
        }
        
      });
  }, []);

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--theme-default',
      store.color || '#ff4c3b'
    );
  }, [store]);
  if (!store) {
    return <div></div>;
  }

  return (
    <div>
      <Head>
        <meta charset="utf-8" />
        <meta
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
          name="viewport"
        />
        <meta name="generator" content="Jarbas" />
        <title>
          {title ? `${title} - ${store.name || ''}` : `${store.name || ''}`}
        </title>
        <link rel="icon" type="image/png" href={imgFavicon} />

      </Head>
      <HeaderOne topClass="top-header" />
      {showBreadCrumbs && (
        <Breadcrumbs title={title} parent={parent} subTitle={subTitle} />
      )}

      <Container>{children}</Container>
      <DynamicMasterFooter
        footerClass={`footer-light`}
        footerLayOut={'light-layout upper-footer'}
        footerSection={'small-section border-section border-top-0'}
        belowSection={'section-b-space light-layout'}
        newLatter={true}
        store={store}
      />
    </div>
  );
}

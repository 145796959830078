import React, { useContext, Fragment } from 'react';
import Link from 'next/link';
import CartHeader from '../headers/common/cart-header';
import CartContext from '../../utils/cart';
import { useTranslation } from 'react-i18next';
import { formatValue } from '../../utils/currency';
import StoreContext from '../../utils/store/StoreContext';
import Image from 'next/image';

const CartContainer = ({ icon }) => {
  const { t } = useTranslation('common');
  const { store } = useContext(StoreContext);
  const context = useContext(CartContext);
  const cartList = context.cartItems;
  const total = context.cartTotal;

  return (
    <Fragment>
      <li className="onhover-div mobile-cart">
        <div className="cart-qty-cls">{cartList.length}</div>
        <Link href={`/checkout`} passHref>
          <a>
            <Image
              src={icon}
              className="img-fluid"
              alt=""
              width={23}
              height={23}
            />
            <i className="fa fa-shopping-cart"></i>
          </a>
        </Link>
        <ul className="show-div shopping-cart">
          {cartList.map((item, index) => (
            <CartHeader key={index} item={item} total={total} />
          ))}
          {cartList.length > 0 ? (
            <div>
              <li>
                <div className="total">
                  <h5>
                    {t('Subtotal')}:{' '}
                    <span>{formatValue(total, store.currency)}</span>
                  </h5>
                </div>
              </li>
              <li>
                <div className="buttons view-cart">
                  <Link href={`/checkout`}>
                    <a>{t('View cart')}</a>
                  </Link>
                </div>
              </li>
            </div>
          ) : (
            <li>
              <h5>{t('Your cart is currently empty')}</h5>
            </li>
          )}
        </ul>
      </li>
    </Fragment>
  );
};

export default CartContainer;

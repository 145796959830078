import React, { useContext } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Link from 'next/link';
import { useRouter } from 'next/router';
import StoreContext from '../../../utils/store/StoreContext';
import CartContext from '../../../utils/cart';
import { useTranslation } from 'react-i18next';
import { getFirstName } from '../../../utils/strings';

const TopBarDark = ({ topClass, fluid }) => {
  const router = useRouter();
  const logout = () => {
    clearCart();
    userLogout();
    router.push('/');
  };
  const { store, userInfo, userLogout } = useContext(StoreContext);
  const { clearCart } = useContext(CartContext);
  const { t } = useTranslation('common');
  return (
    <div className={topClass}>
      <Container fluid={fluid}>
        <Row>
          <Col lg="6">
            <div className="header-contact">
              <ul>
                <li>
                  {t('Welcome To')} {store.name}
                </li>
                {store.phone ? (
                  <li>
                    <i className="fa fa-phone" aria-hidden="true"></i>
                    {t('Phone')}:
                    <Link href={`tel:${store.phone}`} passHref>
                      <a>{store.phone}</a>
                    </Link>
                  </li>
                ) : (
                  <li></li>
                )}
              </ul>
            </div>
          </Col>
          <Col lg="6" className="text-right">
            <ul className="header-dropdown">
              <li className="onhover-dropdown mobile-language">
                <i className="fa fa-globe" aria-hidden="true"></i>{' '}
                {t('Language')}
                <ul className="onhover-show-div">
                  <li>
                    <Link href="#" locale="en">
                      <a>{t('English')}</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="#" locale="pt">
                      <a>{t('Portuguese')}</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="#" locale="es">
                      <a>{t('Spanish')}</a>
                    </Link>
                  </li>
                </ul>
              </li>
              {!store.onlyCatalog && (
                <li className="onhover-dropdown mobile-account">
                  <i className="fa fa-user" aria-hidden="true"></i>{' '}
                  {userInfo ? getFirstName(userInfo.name) : t('My Account')}
                  <ul className="onhover-show-div">
                    {userInfo ? (
                      <>
                        <li>
                          <Link href={`/account/profile`} passHref>
                            <a>{t('Profile')}</a>
                          </Link>
                        </li>
                        <li>
                          <Link href={`/account/profile?item=orders`} passHref>
                            <a>{t('My Orders')}</a>
                          </Link>
                        </li>
                        <li onClick={() => logout()}>
                          <a>{t('Logout')}</a>
                        </li>
                      </>
                    ) : (
                      <>
                        <li>
                          <Link href={`/login`}>
                            <a>{t('Login')}</a>
                          </Link>
                        </li>
                        <li>
                          <Link href={`/register`}>
                            <a>{t('Register')}</a>
                          </Link>
                        </li>
                      </>
                    )}
                  </ul>
                </li>
              )}
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TopBarDark;
